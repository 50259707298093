<template>
  <v-app>
    <v-app-bar app dark>
      <v-img :src="require('./assets/runit_logo_light.svg')" max-width="80" contain />
      <v-spacer />
      <v-btn href="https://monitor.dataspace-private.widder.at" target="_blank" text>
        <span class="mr-2">Monitor</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view @authentification="authentification" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    authenticated: false
  }),
  mounted () {
    if(!this.authenticated)
      this.$router.replace({ name: 'Login' })
  },
  methods: {
    authentification (status) {
      this.authenticated = status
      if (this.authenticated)
        this.$router.replace({ name: 'Usage' })
    }
  }
}
</script>

<style lang="scss">
#app {
  background-color: rgb(245, 245, 245);
}
</style>
